import React from 'react'
import { FaInstagram, FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'

const Navbar = () => {
  return (
    <nav className="w-full py-4 bg-blue-800 shadow">
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between">
        <nav />

        <div className="flex items-center text-lg no-underline text-white pr-6">
          <a href="https://github.com/abolkog" target="_blank" rel="noreferrer">
            <FaGithub />
          </a>
          <a
            className="pl-6"
            href="https://instagram.com/abolkog"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            className="pl-6"
            href="https://twitter.com/abolkog"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            className="pl-6"
            href="https://linkedin.com/in/abolkog"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
