import React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

import Header from './Header'
import Navbar from './Navbar'
import Tags from './Tags'
import { FaTwitter } from 'react-icons/fa'

const Layout = ({ children }) => {
  return (
    <div className="bg-wgute h-screen">
      <Navbar />
      <Header />
      <Tags />
      <div className="container mx-auto flex flex-wrap py-6">
        <section className="w-full md:w-2/3 flex flex-col items-center px-3">
          {children}

          {/* <div className="flex items-center py-8">
            <a
              href="#"
              className="h-10 w-10 bg-blue-800 hover:bg-blue-600 font-semibold text-white text-sm flex items-center justify-center"
            >
              1
            </a>
            <a
              href="#"
              className="h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm flex items-center justify-center"
            >
              2
            </a>
            <a
              href="#"
              className="h-10 w-10 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center ml-3"
            >
              Next <i className="fas fa-arrow-right ml-2"></i>
            </a>
          </div> */}
        </section>

        <aside className="w-full md:w-1/3 flex flex-col items-center px-3">
          <div className="w-full bg-white shadow flex flex-col my-4 p-6">
            <p className="text-xl font-semibold pb-5">About</p>
            <p className="pb-2">
              Welcome to our blog, I share this blog with Sami, my seven years
              old son. We write various things here about my life, my son's
              life, school life and wild imagination or whatever.... Just
              Speaking
            </p>
          </div>

          <div className="w-full bg-white shadow flex flex-col my-4 p-6">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="abolkog"
              noFooter
              options={{ height: 400 }}
            />

            <a
              href="https://twitter.com/intent/user?ref_src=twsrc%5Etfw&region=follow&screen_name=abolkog&tw_p=followbutton"
              className="w-full bg-blue-800 text-white font-bold text-sm rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-6"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter />
              Follow @abolkog
            </a>
          </div>
        </aside>
      </div>
    </div>
  )
}

export default Layout
