import { Link } from 'gatsby'
import React from 'react'

const Header = () => {
  return (
    <header className="w-full container mx-auto">
      <div className="flex flex-col items-center py-12">
        <Link
          to="/"
          className="font-bold text-gray-800 uppercase hover:text-gray-700 text-5xl"
        >
          A.B.O.L.K.O.G
        </Link>
        <p className="text-lg text-gray-600">Just Speaking</p>
      </div>
    </header>
  )
}

export default Header
