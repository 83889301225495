import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { AppContext } from '../context/AppContext'
export const isBrowser = typeof window !== 'undefined'

const Tags = () => {
  // if (!isBrowser) return null
  const { tags = [] } = useContext(AppContext) || {}
  return (
    <nav className="w-full py-4 border-t border-b bg-gray-100">
      <div className="block sm:hidden" />
      <div className="w-full flex-grow sm:flex sm:items-center sm:w-auto">
        <div className="w-full container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm font-bold uppercase mt-0 px-6 py-2">
          {tags.map(tag => (
            <Link
              key={tag.slug}
              className="hover:bg-gray-400 rounded py-2 px-4 mx-2"
              to={`/tag/${tag.slug}`}
            >
              #{tag.title}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default Tags
